window.Parsley.addValidator('protocol', 
  function(value) {
    return ['http://', 'https://'].some(protocol => value.startsWith(protocol));
});

var CheckReccursion = 0;
Parsley.addValidator('checkChildren', {
  messages: {en: 'You must fill out at exactly one input'},
  requirementType: 'string',
  validate: function(_value, requirement, instance) {
    var group = $(requirement);//a class
    var FieldsEmpty = 0;
    var FieldsNotEmpty = 0;
    var count = 0
  
    group.each(function () {

      var _val = $(this).val()
      var length = _val.length	 
      if (length > 0) {
        FieldsNotEmpty++;
      }
      else {
        FieldsEmpty++;
      }
      count++;
    })
    var isValid = (FieldsNotEmpty == 1)
    group.each(function (index) {
      if (CheckReccursion === index) {
        CheckReccursion++;
        $(this).parsley().validate();
        CheckReccursion = 0;
      }
    })
  return isValid;
  } 
});
