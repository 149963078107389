define("ember-toggle-spark/components/toggle-spark/template", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "ember-toggle-spark/components/toggle-spark/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "checkbox");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("label");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createAttrMorph(element0, 'disabled');
        morphs[2] = dom.createAttrMorph(element0, 'onchange');
        morphs[3] = dom.createAttrMorph(element0, 'class');
        morphs[4] = dom.createAttrMorph(element1, 'for');
        morphs[5] = dom.createAttrMorph(element1, 'class');
        morphs[6] = dom.createAttrMorph(element2, 'class');
        morphs[7] = dom.createAttrMorph(element3, 'class');
        return morphs;
      },
      statements: [["attribute", "id", ["get", "inputId", ["loc", [null, [3, 7], [3, 14]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [5, 13], [5, 21]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onchange", ["subexpr", "action", ["onChange"], [], ["loc", [null, [null, null], [6, 32]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", [["subexpr", "unbound", [["get", "__styles__.hidden-checkbox", [], 0, 0, 0, 0]], [], [], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "for", ["get", "inputId", ["loc", [null, [10, 8], [10, 15]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", [["subexpr", "local-class", [["subexpr", "concat", ["\n    ", ["subexpr", "spark", ["toggle"], [], ["loc", [null, [12, 4], [12, 22]]], 0, 0], "\n    ", ["subexpr", "if", [["get", "on", ["loc", [null, [13, 9], [13, 11]]], 0, 0, 0, 0], ["subexpr", "spark", ["on"], [], ["loc", [null, [13, 12], [13, 24]]], 0, 0]], [], ["loc", [null, [13, 4], [13, 26]]], 0, 0], "\n    ", ["subexpr", "if", [["get", "disabled", ["loc", [null, [14, 9], [14, 17]]], 0, 0, 0, 0], ["subexpr", "spark", ["disabled"], [], ["loc", [null, [14, 18], [14, 36]]], 0, 0]], [], ["loc", [null, [14, 4], [14, 38]]], 0, 0], "\n  "], [], [], 0, 0]], ["from", ["subexpr", "unbound", [["get", "__styles__", [], 0, 0, 0, 0]], [], [], 0, 0]], [], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", [["subexpr", "local-class", [["subexpr", "spark", ["highlight"], [], [], 0, 0]], ["from", ["subexpr", "unbound", [["get", "__styles__", [], 0, 0, 0, 0]], [], [], 0, 0]], [], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", [["subexpr", "local-class", [["subexpr", "spark", ["switch"], [], [], 0, 0]], ["from", ["subexpr", "unbound", [["get", "__styles__", [], 0, 0, 0, 0]], [], [], 0, 0]], [], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  }());
});