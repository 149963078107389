define('ember-cli-maskedinput/components/masked-input', ['exports', 'ember', 'inputmask-core', 'ember-cli-maskedinput/util/selection'], function (exports, _ember, _inputmaskCore, _emberCliMaskedinputUtilSelection) {

  var KEYCODE_Z = 90;
  var KEYCODE_Y = 89;
  var KEYCODE_BACKSPACE = 8;

  function isUndo(e) {
    return (e.ctrlKey || e.metaKey) && e.keyCode === (e.shiftKey ? KEYCODE_Y : KEYCODE_Z);
  }

  function isRedo(e) {
    return (e.ctrlKey || e.metaKey) && e.keyCode === (e.shiftKey ? KEYCODE_Z : KEYCODE_Y);
  }

  var PROTECTED_ATTRS = ['change', 'keyDown', 'keyPress', 'paste'];

  exports['default'] = _ember['default'].TextField.extend({
    mask: null,
    formatCharacters: null,
    placeholderChar: '_',

    'on-change': _ember['default'].K,

    validateAttrs: _ember['default'].on('init', function () {
      var _this = this;

      PROTECTED_ATTRS.forEach(function (key) {
        if (_this.attrs[key]) {
          if (console && console.warn) {
            console.warn('WARNING: Attribute "' + key + '" of {{masked-input}} helper should not be overriden' + (key === 'change' ? ', please use "on-${key}" for custom event handlers' : ''));
          }
        }
      });
    }),

    applyMaskToInitialValue: _ember['default'].on('didInsertElement', function () {
      var el = this.get('element');
      el.value = this._getDisplayValue();
    }),

    _inputMask: _ember['default'].computed('mask', 'value', 'formatCharacters', 'placeholderChar', function () {
      var options = {
        pattern: this.get('mask'),
        value: this.get('value'),
        formatCharacters: this.get('formatCharacters')
      };
      var placeholderChar = this.get('placeholderChar');
      if (placeholderChar) {
        options.placeholderChar = placeholderChar;
      }
      return new _inputmaskCore['default'](options);
    }),

    maxlength: _ember['default'].computed('mask', function () {
      var pattern = this.get('mask') || '';
      return pattern.length;
    }),

    placeholder: _ember['default'].computed('_inputMask', function () {
      return this.get('_inputMask').emptyValue;
    }),

    size: _ember['default'].computed('mask', function () {
      var pattern = this.get('mask') || '';
      return pattern.length;
    }),

    _updateMaskSelection: function _updateMaskSelection() {
      var el = this.get('element');
      this.get('_inputMask').selection = (0, _emberCliMaskedinputUtilSelection.getSelection)(el);
    },

    _updateInputSelection: function _updateInputSelection() {
      var el = this.get('element');
      var mask = this.get('_inputMask');
      (0, _emberCliMaskedinputUtilSelection.setSelection)(el, mask.selection);
    },

    _getDisplayValue: function _getDisplayValue() {
      var mask = this.get('_inputMask');
      var value = mask.getValue();
      return value === mask.emptyValue ? '' : value;
    },

    change: function change(e) {
      var mask = this.get('_inputMask');
      var maskValue = mask.getValue();
      if (e.target.value !== maskValue) {
        // Cut or delete operations will have shortened the value
        if (e.target.value.length < maskValue.length) {
          var sizeDiff = maskValue.length - e.target.value.length;
          this._updateMaskSelection();
          mask.selection.end = mask.selection.start + sizeDiff;
          mask.backspace();
        }
        var value = this._getDisplayValue();
        e.target.value = value;
        if (value) {
          this._updateInputSelection();
        }
      }
      this.get('on-change')(e);
    },

    keyDown: function keyDown(e) {
      var mask = this.get('_inputMask');
      if (isUndo(e)) {
        e.preventDefault();
        if (mask.undo()) {
          e.target.value = this._getDisplayValue();
          this._updateInputSelection();
          this.get('on-change')(e);
        }
        return;
      } else if (isRedo(e)) {
        e.preventDefault();
        if (mask.redo()) {
          e.target.value = this._getDisplayValue();
          this._updateInputSelection();
          this.get('on-change')(e);
        }
        return;
      }

      if (e.keyCode === KEYCODE_BACKSPACE) {
        e.preventDefault();
        this._updateMaskSelection();
        if (mask.backspace()) {
          var value = this._getDisplayValue();
          e.target.value = value;
          if (value) {
            this._updateInputSelection();
          }
          this.get('on-change')(e);
        }
      }
    },

    keyPress: function keyPress(e) {
      // Ignore modified key presses
      // Ignore enter key to allow form submission
      if (e.metaKey || e.altKey || e.ctrlKey || e.keyCode === 13) {
        return;
      }

      e.preventDefault();
      this._updateMaskSelection();
      var mask = this.get('_inputMask');
      var key = String.fromCharCode(e.charCode);
      if (mask.input(key)) {
        e.target.value = mask.getValue();
        this._updateInputSelection();
        this.get('on-change')(e);
      }
    },

    paste: function paste(e) {
      e.preventDefault();
      this._updateMaskSelection();
      // getData value needed for IE also works in FF & Chrome
      var mask = this.get('_inputMask');
      if (mask.paste(e.originalEvent.clipboardData.getData('Text'))) {
        e.target.value = mask.getValue();
        // Timeout needed for IE
        _ember['default'].run.next(this, this._updateInputSelection);
        this.get('on-change')(e);
      }
    }
  });
});