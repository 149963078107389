define('ember-select-spark/components/select-spark/component', ['exports', 'ember', 'ember-select-spark/components/select-spark/template', 'ember-select-spark/components/select-spark/styles', 'ember-sparks/components/spark-component'], function (exports, _ember, _emberSelectSparkComponentsSelectSparkTemplate, _emberSelectSparkComponentsSelectSparkStyles, _emberSparksComponentsSparkComponent) {
  var _slice = Array.prototype.slice;
  var computed = _ember['default'].computed;
  exports['default'] = _emberSparksComponentsSparkComponent['default'].extend({
    layout: _emberSelectSparkComponentsSelectSparkTemplate['default'],
    styles: _emberSelectSparkComponentsSelectSparkStyles['default'],

    _selectOptions: computed('options', function () {
      var options = this.get('options');

      var isHashArr = typeof options[0] === "object";

      // Already in the right format:
      if (isHashArr) {
        return options;
      }

      /*
       * If it's just an array of strings,
       * we convert them into objects!
       */
      var hashOptions = [];

      options.forEach(function (label) {
        var optionObj = {
          value: label,
          label: label
        };

        hashOptions.push(optionObj);
      });

      return hashOptions;
    }),

    actions: {

      onChange: function onChange(e) {
        var value = e.target.value;

        this.sendAction.apply(this, ['onChange', value].concat(_slice.call(arguments)));
      }

    }

  });
});