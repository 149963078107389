define('ember-button-spark/components/button-spark/component', ['exports', 'ember', 'ember-button-spark/components/button-spark/template', 'ember-button-spark/components/button-spark/styles', 'ember-sparks/components/spark-component'], function (exports, _ember, _emberButtonSparkComponentsButtonSparkTemplate, _emberButtonSparkComponentsButtonSparkStyles, _emberSparksComponentsSparkComponent) {
  var _slice = Array.prototype.slice;
  var computed = _ember['default'].computed;

  var ButtonComponent = _emberSparksComponentsSparkComponent['default'].extend({
    layout: _emberButtonSparkComponentsButtonSparkTemplate['default'],
    styles: _emberButtonSparkComponentsButtonSparkStyles['default'],

    // @public
    label: '',
    loading: false,
    disabled: false,
    loadingComponent: "button-spark/loading-indicator",

    _isDisabled: computed('disabled', 'loading', function () {
      var disabled = this.get('disabled');
      var loading = this.get('loading');

      return disabled || loading;
    }),

    actions: {

      onClick: function onClick() {
        this.sendAction.apply(this, ['onClick'].concat(_slice.call(arguments)));
      }

    }

  });

  ButtonComponent.reopenClass({
    positionalParams: ['label']
  });

  exports['default'] = ButtonComponent;
});