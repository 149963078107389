define("ember-toggle-spark/components/toggle-spark/styles", ["exports"], function (exports) {
  exports["default"] = {
    "_container": "__container_138p0o",
    "hidden-checkbox": "_hidden-checkbox_138p0o",
    "_toggle": "__toggle_138p0o",
    "_disabled": "__disabled_138p0o",
    "_on": "__on_138p0o",
    "_highlight": "__highlight_138p0o",
    "_switch": "__switch_138p0o"
  };
});