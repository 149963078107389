define("ember-button-spark/components/button-spark/styles", ["exports"], function (exports) {
  exports["default"] = {
    "_container": "__container_wntkn4",
    "_button": "__button_wntkn4",
    "_has-label": "__has-label_wntkn4",
    "_icon": "__icon_wntkn4",
    "_label": "__label_wntkn4",
    "_loader": "__loader_wntkn4",
    "_loading": "__loading_wntkn4",
    "loader": "_loader_wntkn4",
    "icon": "_icon_wntkn4"
  };
});