define('ember-select-spark/components/select-spark/select-option/component', ['exports', 'ember', 'ember-select-spark/components/select-spark/select-option/template'], function (exports, _ember, _emberSelectSparkComponentsSelectSparkSelectOptionTemplate) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;

  /* 
   * This component mostly exists just so that we can avoid
   * requiring ember-truth-helpers as a dependency for selected="true"
   */

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberSelectSparkComponentsSelectSparkSelectOptionTemplate['default'],
    tagName: 'option',

    attributeBindings: ['value', 'isSelected:selected'],

    isSelected: computed('selectedValue', 'value', function () {
      var selectedValue = this.get('selectedValue');
      var value = this.get('value');

      return selectedValue === value;
    })

  });
});