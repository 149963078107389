define('ember-input-spark/components/input-spark/component', ['exports', 'ember', 'ember-input-spark/components/input-spark/template', 'ember-input-spark/components/input-spark/styles', 'ember-sparks/components/spark-component'], function (exports, _ember, _emberInputSparkComponentsInputSparkTemplate, _emberInputSparkComponentsInputSparkStyles, _emberSparksComponentsSparkComponent) {
  var _slice = Array.prototype.slice;
  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var later = _ember['default'].run.later;
  exports['default'] = _emberSparksComponentsSparkComponent['default'].extend({
    layout: _emberInputSparkComponentsInputSparkTemplate['default'],
    styles: _emberInputSparkComponentsInputSparkStyles['default'],

    /*
     * @public
     */
    value: null,
    prefix: null,
    type: "text",
    placeholder: null,
    readonly: true,
    disabled: false,
    label: true,
    error: null,
    errorComponent: "input-spark/error-spark",
    scrollOnError: false,

    VALID_TYPES: ['text', 'email', 'search', 'tel', 'url', 'password'],

    /*
     * @private
     */
    _showError: false,
    _typingTimer: null,
    _typingInterval: 1000,

    _inputType: computed('type', {
      get: function get() {
        var type = this.get('type');

        if (this.VALID_TYPES.indexOf(type) === -1) {
          assert('input-spark does not support type="' + type + '"!');
        }

        return type;
      }
    }),

    _inputLabel: computed('label', 'placeholder', {
      get: function get() {
        var label = this.get('label');
        var placeholder = this.get('placeholder');

        if (typeof label === "string") {
          return label;
        } else if (label) {
          return placeholder;
        }
      }
    }),

    valueDidChange: observer('value', function () {
      this.set('error', null);
    }),

    errorDidChange: on('init', observer('error', function () {
      var _this = this;

      var error = this.get('error');

      var ERROR_FADE_DURATION = 300;

      if (error) {
        this.set('_showError', true);
      } else {
        /*
         * Let the error object animate
         * before destroying:
         */
        later(function () {
          _this.set('_showError', false);
        }, ERROR_FADE_DURATION);
      }
    })),

    _startTypingTimer: function _startTypingTimer() {
      var _this2 = this;

      var typingTimer = this.get('_typingTimer');
      var typingInterval = this.get('_typingInterval');

      clearTimeout(typingTimer);

      typingTimer = setTimeout(function () {
        return _this2.sendAction('onFinishTyping');
      }, typingInterval);
      this.set('_typingTimer', typingTimer);
    },

    actions: {

      // Called when clicking on the virtual-input:
      focus: function focus() {
        var $input = this.$('input');

        if (!$input.is(":focus")) {
          $input.focus();
        }
      },

      onFocus: function onFocus() {
        this.set('_isFocused', true);
        this.sendAction.apply(this, ['onFocus'].concat(_slice.call(arguments)));
      },

      onBlur: function onBlur() {
        this.set('_isFocused', false);
        this.sendAction.apply(this, ['onBlur'].concat(_slice.call(arguments)));
      },

      onKeyUp: function onKeyUp(e) {
        var value = e.target.value;

        this.sendAction('onKeyUp', value, e);

        if (e.key === "Enter") {
          this.sendAction('onEnter', value, e);
        }
      },

      onKeyDown: function onKeyDown(e) {
        var value = e.target.value;

        this.sendAction('onKeyDown', value, e);
      },

      onInput: function onInput(e) {
        var value = e.target.value;

        this.sendAction('onInput', value, e);

        this._startTypingTimer();
      },

      onChange: function onChange(e) {
        var value = e.target.value;
        this.sendAction('onChange', value, e);
      }

    }

  });
});