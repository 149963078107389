define('ember-toggle-spark/components/toggle-spark/component', ['exports', 'ember', 'ember-toggle-spark/components/toggle-spark/template', 'ember-toggle-spark/components/toggle-spark/styles', 'ember-sparks/components/spark-component'], function (exports, _ember, _emberToggleSparkComponentsToggleSparkTemplate, _emberToggleSparkComponentsToggleSparkStyles, _emberSparksComponentsSparkComponent) {
  var _slice = Array.prototype.slice;
  var computed = _ember['default'].computed;

  var ToggleComponent = _emberSparksComponentsSparkComponent['default'].extend({
    layout: _emberToggleSparkComponentsToggleSparkTemplate['default'],
    styles: _emberToggleSparkComponentsToggleSparkStyles['default'],

    // @public
    on: false,
    disabled: false,

    inputId: computed('elementId', function () {
      var elementId = this.get('elementId');
      return elementId + '-input';
    }),

    actions: {
      onChange: function onChange(e) {
        var isOn = e.target.checked;

        this.set('on', isOn);

        this.sendAction.apply(this, ['onChange', isOn].concat(_slice.call(arguments)));
      }
    }

  });

  ToggleComponent.reopenClass({
    positionalParams: ['on']
  });

  exports['default'] = ToggleComponent;
});