define('ember-input-spark/components/input-spark/error-spark/component', ['exports', 'ember', 'ember-input-spark/components/input-spark/error-spark/template', 'ember-input-spark/components/input-spark/error-spark/styles', 'ember-sparks/components/spark-component'], function (exports, _ember, _emberInputSparkComponentsInputSparkErrorSparkTemplate, _emberInputSparkComponentsInputSparkErrorSparkStyles, _emberSparksComponentsSparkComponent) {
  var $ = _ember['default'].$;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var _Ember$run = _ember['default'].run;
  var later = _Ember$run.later;
  var scheduleOnce = _Ember$run.scheduleOnce;
  var Promise = _ember['default'].RSVP.Promise;
  exports['default'] = _emberSparksComponentsSparkComponent['default'].extend({
    layout: _emberInputSparkComponentsInputSparkErrorSparkTemplate['default'],
    styles: _emberInputSparkComponentsInputSparkErrorSparkStyles['default'],

    tagName: 'span',

    _errorMessage: null,

    localClassNameBindings: ['visible'],

    visible: computed('error', '_errorMessage', {
      get: function get() {
        var error = this.get('error');
        var errorMessage = this.get('_errorMessage');

        return error && errorMessage;
      }
    }),

    errorDidChange: on('init', observer('error', function () {
      var _this = this;

      scheduleOnce('afterRender', function () {
        _this.handleError();
      });
    })),

    handleError: function handleError() {
      var _this2 = this;

      var errMessage = this.get('error');
      var shouldScroll = this.get('scrollOnError');

      if (!errMessage) {
        return;
      }

      if (shouldScroll === false) {
        this._showError();
      } else {
        this._scrollToError().then(function () {
          _this2._showError();
        });
      }
    },

    _showError: function _showError() {
      var _this3 = this;

      var DELAY = 100;
      var errMessage = this.get('error');

      later(function () {
        _this3.set('_errorMessage', errMessage);
      }, DELAY);
    },

    /*
     * Scroll to error field
     * before showing error:
     */
    _scrollToError: function _scrollToError() {
      var _this4 = this;

      var OFFSET = 200;
      var SCROLL_DURATION = 300;

      return new Promise(function (resolve) {
        var elPos = _this4.$().offset();
        var elPosTop = elPos && elPos.top;

        $('html, body').animate({
          scrollTop: elPosTop - OFFSET
        }, SCROLL_DURATION, function () {
          return resolve();
        });
      });
    }

  });
});