define("ember-input-spark/components/input-spark/styles", ["exports"], function (exports) {
  exports["default"] = {
    "box-shadow-position": "0 0 0 1px",
    "box-shadow-color": "rgba(0, 0, 0, 0.16)",
    "box-shadow-color-hover": "rgba(0, 0, 0, 0.22)",
    "box-shadow-color-focus": "#93ccee",
    "text-color": "rgba(0, 0, 0, 0.8)",
    "error-bg": "#F05A5C",
    "error-color": "white",
    "_container": "__container_mdnmkg",
    "_label": "__label_mdnmkg",
    "_visible": "__visible_mdnmkg",
    "_input": "__input_mdnmkg",
    "_disabled": "__disabled_mdnmkg",
    "_focused": "__focused_mdnmkg",
    "_core-input": "__core-input_mdnmkg",
    "_icon": "__icon_mdnmkg",
    "_prefix": "__prefix_mdnmkg",
    "_error": "__error_mdnmkg"
  };
});