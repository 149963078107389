define("ember-button-spark/components/button-spark/template", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 2
            },
            "end": {
              "line": 14,
              "column": 2
            }
          },
          "moduleName": "ember-button-spark/components/button-spark/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "local-class", [["subexpr", "spark", ["icon"], [], [], 0, 0]], ["from", ["subexpr", "unbound", [["get", "__styles__", [], 0, 0, 0, 0]], [], [], 0, 0]], [], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "yield", ["loc", [null, [12, 6], [12, 15]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 2
            },
            "end": {
              "line": 20,
              "column": 2
            }
          },
          "moduleName": "ember-button-spark/components/button-spark/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "local-class", [["subexpr", "spark", ["label"], [], [], 0, 0]], ["from", ["subexpr", "unbound", [["get", "__styles__", [], 0, 0, 0, 0]], [], [], 0, 0]], [], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "label", ["loc", [null, [18, 6], [18, 15]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "ember-button-spark/components/button-spark/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element2, 'disabled');
        morphs[1] = dom.createAttrMorph(element2, 'onclick');
        morphs[2] = dom.createAttrMorph(element2, 'class');
        morphs[3] = dom.createMorphAt(element2, 1, 1);
        morphs[4] = dom.createMorphAt(element2, 3, 3);
        morphs[5] = dom.createMorphAt(element2, 5, 5);
        return morphs;
      },
      statements: [["attribute", "disabled", ["subexpr", "if", [["get", "_isDisabled", ["loc", [null, [6, 16], [6, 27]]], 0, 0, 0, 0], "true"], [], ["loc", [null, [null, null], [6, 36]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["onClick"], [], ["loc", [null, [null, null], [7, 30]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", [["subexpr", "local-class", [["subexpr", "concat", ["\n  ", ["subexpr", "spark", ["button"], [], ["loc", [null, [2, 2], [2, 20]]], 0, 0], "\n  ", ["subexpr", "if", [["get", "loading", ["loc", [null, [3, 7], [3, 14]]], 0, 0, 0, 0], ["subexpr", "spark", ["loading"], [], ["loc", [null, [3, 15], [3, 32]]], 0, 0]], [], ["loc", [null, [3, 2], [3, 34]]], 0, 0], "\n  ", ["subexpr", "if", [["get", "label", ["loc", [null, [4, 7], [4, 12]]], 0, 0, 0, 0], ["subexpr", "spark", ["has-label"], [], ["loc", [null, [4, 13], [4, 32]]], 0, 0]], [], ["loc", [null, [4, 2], [4, 34]]], 0, 0], "\n"], [], [], 0, 0]], ["from", ["subexpr", "unbound", [["get", "__styles__", [], 0, 0, 0, 0]], [], [], 0, 0]], [], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "hasBlock", ["loc", [null, [10, 8], [10, 16]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 2], [14, 9]]]], ["block", "if", [["get", "label", ["loc", [null, [16, 8], [16, 13]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [16, 2], [20, 9]]]], ["inline", "component", [["get", "loadingComponent", ["loc", [null, [22, 14], [22, 30]]], 0, 0, 0, 0]], ["class", ["subexpr", "concat", [["subexpr", "local-class", [["subexpr", "spark", ["loader"], [], ["loc", [null, [23, 16], [23, 32]]], 0, 0]], ["from", ["subexpr", "unbound", [["get", "__styles__", [], 0, 0, 0, 0]], [], [], 0, 0]], [], 0, 0]], [], [], 0, 0]], ["loc", [null, [22, 2], [24, 4]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});