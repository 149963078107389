define("ember-select-spark/components/select-spark/styles", ["exports"], function (exports) {
  exports["default"] = {
    "arrow-width": "6px",
    "box-shadow-value": "0px 0px 0px 1px rgba(0, 0, 0, 0.13)",
    "_container": "__container_5u6lq8",
    "_icon": "__icon_5u6lq8",
    "_select": "__select_5u6lq8",
    "_arrow": "__arrow_5u6lq8",
    "_has-icon": "__has-icon_5u6lq8"
  };
});