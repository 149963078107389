define("ember-sparks/helpers/spark", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = SparkClass;

  /*
   * Generates both public ("input")
   * and private ("_input") class names
   * to be used with ember-css-modules
   */

  function SparkClass(params) {
    var result = "";

    params.forEach(function (className) {
      result += "_" + className + " " + className;
    });

    return result;
  }

  exports["default"] = _ember["default"].Helper.helper(SparkClass);
});